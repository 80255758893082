import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types';
import moment from 'moment'

const useStyles = makeStyles(() => ({
  bodyItem: {
    maxHeight: '200px',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    display: "flex",
    padding: 5,
    textAlign: 'left',
    overflow: 'auto',
    textOverflow: 'ellipsis',
    '& p': {
      fontSize: 10
    }
  },
  bodyRow: {
    minHeight: 35,
    transition: 'all .3s',
    '&:hover': {
      background: '#f0f0f3'
    }
  },
  tdItem: {
    border: 'none !important',
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  trItem: {
    border: '1px solid black',
    overflow: 'auto',
    maxHeight: '140px',
    whiteSpace: 'normal',
  }
}))

const TableUnclaimedPaymentsSynchronizationItem = ({ Timestamp, Status, duration, NumberOfReceivedPayments, NumberOfTransactionsFound, Error }) => {
  const classes = useStyles();
  return (
    <tr colSpan={16} style={{ padding: 0 }} className={classes.trItem}>
      <td className={classes.tdItem}>
        <Grid item className={classes.bodyItem}><Typography variant="body2">{moment(Timestamp).format('YYYY-MM-DD HH:mm:ss Z')}</Typography></Grid>
      </td>
      <td className={classes.tdItem}>
        <Grid item className={classes.bodyItem}><Typography variant="body2">{Status}</Typography></Grid>
      </td>
      <td className={classes.tdItem}>
        <Grid item className={classes.bodyItem}><Typography variant="body2">{NumberOfReceivedPayments}</Typography></Grid>
      </td>
      <td className={classes.tdItem}>
        <Grid item className={classes.bodyItem}><Typography variant="body2">{NumberOfTransactionsFound}</Typography></Grid>
      </td>
      <td className={classes.tdItem}>
        <Grid item className={classes.bodyItem}><Typography variant="body2">{Error}</Typography></Grid>
      </td>
    </tr>
  )
}

TableUnclaimedPaymentsSynchronizationItem.propTypes = {
  Id: PropTypes.string,
  Status: PropTypes.string,
}

TableUnclaimedPaymentsSynchronizationItem.defaultProps = {
  Id: null,
  Status: null,
}

export default TableUnclaimedPaymentsSynchronizationItem;