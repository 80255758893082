import { syncTransactionsApiGet } from '../api'

import {
  INIT_GRID,
  LOADING_GRID,
  UPDATE_GRID,
} from '@root/constants'

const loadTableUnclaimedPaymentsSynchronizationController = (query) => async (dispatch) => {
  try {
    dispatch({type: INIT_GRID, payload: { gridId:  `TableUnclaimedPaymentsSynchronization`, query: query} })
    dispatch({type: LOADING_GRID, payload: { gridId:  `TableUnclaimedPaymentsSynchronization`, isLoading: true } })
    const syncList = await syncTransactionsApiGet(query);
    dispatch({type: UPDATE_GRID, payload: { gridId:  `TableUnclaimedPaymentsSynchronization`, items: syncList.value, countItems: syncList['@odata.count']} })
    dispatch({type: LOADING_GRID, payload: { gridId:  `TableUnclaimedPaymentsSynchronization`, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}

const updateTableUnclaimedPaymentsSynchronizationController= (query) => async (dispatch) => {
  try {
    dispatch({type: LOADING_GRID, payload: { gridId: `TableUnclaimedPaymentsSynchronization`, isLoading: true } })
    const syncList = await syncTransactionsApiGet(query);
    dispatch({type: UPDATE_GRID, payload: { gridId: `TableUnclaimedPaymentsSynchronization`, items: syncList.value, countItems: syncList['@odata.count'], query: query } })
    dispatch({type: LOADING_GRID, payload: { gridId: `TableUnclaimedPaymentsSynchronization`, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
} 

export {
  loadTableUnclaimedPaymentsSynchronizationController,
  updateTableUnclaimedPaymentsSynchronizationController
}