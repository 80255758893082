import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@material-ui/core'
import { Route, Switch, Redirect, useRouteMatch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import { compose } from 'redux';
import { connect } from 'react-redux'
import { logoutController } from './controller'
import SvgIcon from '@material-ui/core/SvgIcon';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Sync from '@material-ui/icons/Sync';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TableChartIcon from '@material-ui/icons/TableChart';
import AdminComponent from '@components/AdminComponent/AdminComponent'
import AccountComponent from '@components/AccountComponent/AccountComponent'
import TableSynchronization from '@components/AccountComponent/components/TableSynchronization'
import TableAllCurrentSync from '@components/AccountComponent/components/TableAllCurrentSync'
import TableFailedTransactions from '@components/AccountComponent/components/TableFailedTransactions'
import TableUnclaimedPaymentsSynchronization from '@components/AccountComponent/components/TableUnclaimedPaymentsSynchronization'

import TableTransactionsCurrency from '@components/AccountComponent/components/TableTransactionsCurrency'

const AdminPage = ({ location: { pathname }, history: { push }, logout }, ...props) => {
  const { path } = useRouteMatch();

  const isActivePage = () => {
    if (pathname.includes('/admin/account')) return 'account'
    if (pathname.includes('/admin/users')) return 'users'
    if (pathname.includes('/admin/failed-transaction')) return 'failed-transaction'
    if (pathname.includes('/admin/unclaimed-payments-synchronization')) return 'unclaimed-payments-synchronization'
  }

  const isActive = isActivePage()

  const handleClick = path => {
    push(path)
  }

  const handleClickLogout = () => {
    logout()
    push('/auth')
  }

  return (
    <Box p={1}>
      <AppBar>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center">
            <Box pr={1}>
              <DashboardIcon />
            </Box>

            <Typography variant="h6">Admin panel</Typography>
          </Box>

          <Button color="inherit" variant="outlined" onClick={handleClickLogout}>
            <Box display="flex" alignItems="center">
              <Typography>Exit</Typography>
              <SvgIcon style={{ fontSize: 18, paddingLeft: 8 }}>
                <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
              </SvgIcon>
            </Box>
          </Button>
        </Toolbar>

      </AppBar>
      <Box pt={10} display="flex">
        <Box pr={1}>
          <Button variant={isActive === 'account' ? 'outlined' : 'contained'} color="primary" onClick={() => handleClick('/admin/account')} startIcon={<TableChartIcon />}>
            <Typography>Accounts</Typography>
          </Button>
        </Box>
        <Box pr={1}>
          <Button variant={isActive === 'users' ? 'outlined' : 'contained'} color="primary" onClick={() => handleClick('/admin/users')} startIcon={<SupervisorAccountIcon />}>
            <Typography>Admins</Typography>
          </Button>
        </Box>
        <Box pr={1}>
          <Button variant={isActive === 'failed-transaction' ? 'outlined' : 'contained'} color="primary" onClick={() => handleClick('/admin/failed-transaction')} startIcon={<SmsFailedIcon />}>
            <Typography>Failed transactions</Typography>
          </Button>
        </Box>

        <Box pr={1}>
          <Button variant={isActive === 'unclaimed-payments-synchronization' ? 'outlined' : 'contained'} color="primary" onClick={() => handleClick('/admin/unclaimed-payments-synchronization')} startIcon={<Sync />}>
            <Typography>Synchronization of unclaimed payments</Typography>
          </Button>
        </Box>
      </Box>

      <Switch>
        <Route exact path={`${path}/account/sync/:id`} component={TableSynchronization} />
        <Route exact path={`${path}/account/transaction/:id`} component={TableAllCurrentSync} />
        <Route exact path={`${path}/account/transaction-currency/:id`} component={TableTransactionsCurrency} />

        <Route path={`${path}/account`} component={AccountComponent} />
        <Route path={`${path}/users`} component={AdminComponent} />
        <Route path={`${path}/failed-transaction`} component={TableFailedTransactions} />
        <Route path={`${path}/unclaimed-payments-synchronization`} component={TableUnclaimedPaymentsSynchronization} />
        <Route path={`${path}/*`} component={() => <Redirect to={`${path}/account`} />} />
      </Switch>
    </Box>
  )
}

AdminPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  logout: PropTypes.func.isRequired
}

const enhance = compose(
  connect(null, {
    logout: logoutController
  }),
  withRouter
)

export default enhance(AdminPage)
