import React, { useEffect, useState } from 'react';
import { loadTableUnclaimedPaymentsSynchronizationController, updateTableUnclaimedPaymentsSynchronizationController } from '../controller'
import { connect } from 'react-redux';
import { compose } from 'redux'
import { closeGridController, getGridById } from '@root/controller'
import { toggleSort } from '@root/helperFunctions'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import SearchTextField from '@helper/SearchTextField/SearchTextField'
import SearchSelect from '@helper/SearchSelect/SearchSelect'
import CheckAll from '@helper/CheckAll/CheckAll'
import Refresh from '@material-ui/icons/Refresh';
import { Grid, Box, Button, Typography } from '@material-ui/core'
import HeadStyledTableCell from '@components/AccountComponent/components/TableAccount/Template/HeadStyledTableCell'
import { useStyles } from '@components/AccountComponent/styles';
import Pagination from '@helper/Pagination/Pagination';
import TableLoader from '@helper/Table/components/TableLoader';
import TableUnclaimedPaymentsSynchronizationItem from './TableUnclaimedPaymentsSynchronizationItem';
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { PushTransactionsController, IgnoreTransactionsController } from '@components/AccountComponent/controller'

import { syncTransactionsApi, syncTransactionsApiGetStatus } from '../api';


const TableUnclaimedPaymentsSynchronization = ({
  loadTableUnclaimedPaymentsSynchronization,
  updateTableUnclaimedPaymentsSynchronization,
  closeGrid,
  items,
  countItems,
  initialValues: { fieldSort, typeSort, top, skip },
  isLoading
}) => {

  const styles = makeStyles(() => ({
    tableSync: {
      tableLayout: "fixed"
    }
  }))

  const componentSyles = styles();
  const classes = useStyles();

  const [sort, setSort] = useState({ field: fieldSort, type: typeSort })
  const [pagination, setPagination] = useState({ top: top, skip: skip })

  const startQuery = `?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.skip}&$count=true`;

  const handleChangePagination = (e, page) => {

    setPagination({ ...pagination, skip: pagination.top * page - pagination.top })
    updateTableUnclaimedPaymentsSynchronization(`?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.top * page - pagination.top}&$count=true&`)
  }

  const configHeadTitle = [
    {
      width: 17, titleColumn: 'Date',
      titleColumnSort: 'Timestamp', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 17, titleColumn: 'Status',
      titleColumnSort: 'Status', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 17, titleColumn: 'Number of unclaimed payments received',
      titleColumnSort: 'NumberOfReceivedPayments', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 17, titleColumn: 'Number of transactions found',
      titleColumnSort: 'NumberOfTransactionsFound', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 28, titleColumn: 'Errors',
      titleColumnSort: 'Error', isSort: true, currentSortField: sort.field, sortType: sort.type
    }
  ];

  useEffect(() => {
    loadTableUnclaimedPaymentsSynchronization(startQuery);

    return () => {
      closeGrid({ gridId: `TableUnclaimedPaymentsSynchronization` })
    }
  }, [])

  const startSynchronization = async () => {
    await syncTransactionsApi();
    await refreshTable();
  };

  const refreshTable = async () => {
    await updateTableUnclaimedPaymentsSynchronization(startQuery)
  }

  return (

    <Box pt={2}>
      <Box>
        <Box pb={1} display="flex">
          <Box pr={1}>
            <Button variant={'outlined'} color="primary" onClick={() => startSynchronization()} >
              <Typography>Start Synchronization</Typography>
            </Button>
          </Box>
          <Box pr={1}>
            <Button variant={'outlined'} color="primary" onClick={() => refreshTable()} startIcon={<Refresh />}>
              <Typography>Refresh table</Typography>
            </Button>
          </Box>
        </Box>

      </Box>
      <Grid container>
        <Grid item xs={12} style={{ overflowX: 'auto' }}>
          <Table className={`${classes.table} ${componentSyles.tableSync}`}>
            <TableHead className={classes.hoverHead}>
              <TableRow>
                {
                  configHeadTitle.map(({ titleColumnSort, isSort, currentSortField, width, titleColumn, sortType }) => {
                    return (
                      <HeadStyledTableCell
                        key={titleColumn}
                        titleColumnSort={titleColumnSort}
                        isSort={isSort}
                        currentSortField={currentSortField}
                        width={width}
                        widthPx={true}
                        percent={true}
                        titleColumn={titleColumn}
                        sortType={sortType}
                      />
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                isLoading ? (
                  <tr>
                    <td colSpan={15}>
                      <Box py={5}><TableLoader /></Box>
                    </td>
                  </tr>
                ) : (
                  items.length
                    ? (items.map(data => <TableUnclaimedPaymentsSynchronizationItem key={data.id} {...data} />))
                    : <tr><td colSpan={16} style={{ textAlign: 'center' }}>No data</td></tr>
                )
              }
            </TableBody>
          </Table>
        </Grid>
        <Box pt={1}>
          <Pagination top={pagination.top} skip={pagination.skip} totalCount={countItems} onChange={handleChangePagination} />
        </Box>
      </Grid>
    </Box>
  )
}

TableUnclaimedPaymentsSynchronization.propTypes = {
  loadTableUnclaimedPaymentsSynchronization: PropTypes.func.isRequired,
  updateTableUnclaimedPaymentsSynchronization: PropTypes.func.isRequired
}

TableUnclaimedPaymentsSynchronization.defaultProps = {
  items: [],
  countItems: 0,
  isLoading: false,
  initialValues: {
    fieldSort: 'Timestamp',
    typeSort: 'desc',
    top: 10,
    skip: 0
  }
}

const enhance = compose(
  connect((state) => {
    const { items, countItems, isLoading } = getGridById(state, { gridId: `TableUnclaimedPaymentsSynchronization` })
    return {
      items,
      countItems,
      isLoading
    }
  }, {
    loadTableUnclaimedPaymentsSynchronization: loadTableUnclaimedPaymentsSynchronizationController,
    closeGrid: closeGridController,
    updateTableUnclaimedPaymentsSynchronization: updateTableUnclaimedPaymentsSynchronizationController,
    PushTransactions: PushTransactionsController,
    IgnoreTransactions: IgnoreTransactionsController,
  })
)

export default enhance(TableUnclaimedPaymentsSynchronization)
